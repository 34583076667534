import React from 'react'

import SEO from '../components/seo'
import abductionIllustration from '../images/abduction-illustration.svg'

function NotFoundPage() {
    return (
        <div>
            <SEO title="404: Not found" />
            <div>
                <img
                    src={abductionIllustration}
                    className="block mx-auto w-1/2"
                    alt="Ghost getting abducted by aliens"
                />
                <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
                    Looks like this page is a ghost that got abducted by aliens...
                </h2>
            </div>
        </div>
    )
}

export default NotFoundPage
